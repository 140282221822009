@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --primary-color: #42200b;
  --secondary-color: #ffc135;
  --tertiary-color: #df1c1c;
  --text-dark: #212529;
  --white: #ffffff;
  --max-width: 1200px;
  --header-font-1: "Alfa Slab One", serif;
  --header-font-2: "Bebas Neue", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.section__container {
  max-width: var(--max-width);
  margin: auto;
  padding: 5rem 1rem;
}

.stickybtn{
  position: fixed;
  background-color: #ffc135;
 
  bottom: 15px;
  right: 15px;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  width: max-content;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.stickybtn a{
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.stickybtn i{
  margin-right: 5px;
}

.section__header {
  font-size: 3rem;
  font-weight: 500;
  font-family: var(--header-font-1);
  color: #ffc135;
  text-align: center;
  line-height: 3.75rem;

}

.section__description {
  font-weight: 500;
  color: var(--text-dark);
  line-height: 1.75rem;
}


.btn {
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #ffc135;
  transition: 0.3s;
  cursor: pointer;
  font-weight: bold;

}

.catering .btn{
  padding: 1rem 1.5rem;
  outline: none;
  border: none;
  font-size: 1rem;
  color: #ffc135;
  border-radius: 5px;
  background-color: #fff;
  transition: 0.3s;
  cursor: pointer;
  font-weight: bold;
  /* position: relative;
  left: 155px; */
}

/* Burger menu button */
.nav__menu__btn {
  font-size: 24px;
  cursor: pointer;
  display: none; /* Initially hidden */
}

.nav__menu__btn img{
width: 50px;
height: 50px;
}



img {
  display: flex;
  width: 100%;
}

a {
  text-decoration: none;
  transition: 0.3s;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
}

.header {
  background-color: #393333;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

nav {
  position: fixed;
  width: 100%;
  max-width: var(--max-width);
  margin-inline: auto;
  z-index: 9;
}

.nav__header {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #393333;
}

.nav__logo img {
  max-width: 150px;
}

.nav__logo-dark {
  display: none;
}

.nav__menu__btn {
  font-size: 1.5rem;
  color: var(--white);
  cursor: pointer;
}

.nav__links {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  padding: 2rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--primary-color);
  transition: 0.5s;
  z-index: -1;
  transform: translateY(-100%);
}

.nav__links.open {
  transform: translateY(0);
}

.nav__links a {
  font-weight: 600;
  white-space: nowrap;
  color: #ffffff;
  transition: 0.3s;
}

.nav__links a:hover {
  color: var(--secondary-color);
}

.header__container {
  display: grid;
  gap: 2rem;
  overflow: hidden;
}

.header__image img {
  max-width: 600px;
  margin-inline: auto;
}

.header__content h2 {
  max-width: 400px;
  margin-inline: auto;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  font-size: 1.75rem;
  font-weight: 400;
  font-family: var(--header-font-2);
  color: var(--primary-color);
  border: 2px dashed var(--primary-color);
  text-align: center;
}

.colorwhite{
  color: #fff;
}

.header__content h1 {
  font-size: 4rem;
  margin-bottom: 50px;
  font-weight: 500;
  font-family: var(--header-font-1);
  color: #ffc135;
  line-height: 3.5rem;
}

.catering h1 {
  font-size: 4rem;
  margin-bottom: 50px;
  text-align: center;
  font-weight: 500;
  font-family: var(--header-font-1);
  color: #ffc135;
  line-height: 3.5rem;
}

.header__content h1 span {
  font-size: 3rem;
}

.banner__container {
  display: grid;
  gap: 1rem;
  grid-auto-rows: 200px;
}

.banner__card {
  padding: 1rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 1rem;
}

.banner__card:nth-child(1) {
  background-image: url("assets/banner-1.png");
}

.banner__card:nth-child(2) {
  background-image: url("assets/banner-2.png");
}

.banner__card:nth-child(3) {
  background-image: url("assets/banner-3.png");
}

.banner__card p {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--white);
}

.banner__card h4 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--white);
}

.order__container h3 {
  max-width: fit-content;
  margin-inline: auto;
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: var(--header-font-2);
  color: var(--primary-color);
  background-color: var(--secondary-color);
}

.order__container .section__header {
  margin-bottom: 1rem;
}

.order__container .section__description {
  margin-bottom: 2rem;
  text-align: center;
}

.order__grid {
  display: grid;
  gap: 2rem 1rem;
}

.order__card {
  padding: 2rem 1rem;
  border-radius: 1rem;
  text-align: center;
  transition: 0.3s;
}

.order__card:hover {
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}

.order__card img {
  max-width: 250px;
  margin-inline: auto;
  margin-bottom: 2rem;
  filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.3));
}

.order__card h4 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-dark);
}

.order__card p {
  margin-bottom: 2rem;
  font-weight: 500;
  color: var(--text-dark);
  line-height: 1.75rem;
}

.event__content {
  display: grid;
  gap: 3rem;
  padding: 1rem;
  /* box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1); */
}

.event__details {
  text-align: center;
}

.event__details h3 {
  font-size: 2rem;
  font-weight: 500;
  font-family: var(--header-font-2);
  color: var(--text-dark);
}

.event__details .section__header {
  margin-bottom: 1rem;
}

.reservation {
  position: relative;
  isolation: isolate;
}

.reservation__container h3 {
  font-size: 2rem;
  font-weight: 500;
  font-family: var(--header-font-2);
  color: var(--text-dark);
  text-align: center;
}

.reservation__container form {
  max-width: 400px;
  margin-inline: auto;
  margin-top: 4rem;
  display: grid;
  gap: 1rem;
}

.reservation__container input {
  padding: 0.75rem 1rem;
  outline: none;
  border: 1px solid var(--text-dark);
  font-size: 1rem;
  color: var(--text-dark);
  width: 100%;
}
.cateringcontent{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 1.17em;
}

.catering{
  background-color: #393333;
  padding: 30px;
}

.catering h3{
  color: #fff;
}

.reservation__container input::placeholder {
  color: var(--text-dark);
}

.footer__content{
margin-left: 30px;
margin-right: 30px;

}

.reservation img {
  display: none;
}


input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}



.absdate{
  position: absolute;
  margin-left: 20px;
  margin-top: 12px;
}

input[type=date]:focus + .absdate {
  display: none;
  color: transparent;
}

.footer {
  background-image: url("assets/footerimg1.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer__logo img {
  max-width: 250px;
}

.footer__content {
  margin-top: 2rem;
  display: grid;
  gap: 2rem;
}

.footer__content p {
  font-weight: 5500;
  color: var(--white);
  line-height: 1.75rem;
}

.footer__links {
  list-style: none;
  display: grid;
  gap: 1rem;
}

.footer__links li {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  color: var(--white);
}

.footer__links li span {
  font-size: 1.25rem;
}

.footer__socials {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer__socials a {
  font-size: 1.5rem;
  color: var(--white);
}

.footer__socials a:hover {
  color: var(--secondary-color);
}

.footer__bar {
  padding: 1rem;
  font-size: 0.9rem;
  color: var(--white);
  text-align: center;
}

@media (width > 540px) {
  .banner__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .banner__card:nth-child(1) {
    grid-area: 1/1/2/3;
  }

  .order__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width > 768px) {
  nav {
    position: static;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .nav__header {
    padding: 0;
    background-color: transparent;
  }


  .nav__logo-dark {
    display: flex;
  }

  .nav__logo-white {
    display: none;
  }

  .nav__menu__btn {
    display: none;
  }

  .nav__links {
    position: static;
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
    background-color: transparent;
    transform: none;
    z-index: 1;
  }
  .border100{
    border-radius: 100%;
  }
  .border50{
    border-radius: 50px;
  }

  .nav__links a {
    color: #fff;
  }

  .nav__links a:hover {
    color: #ffc135;
  }

  .header__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .header__image {
    grid-area: 1/2/2/3;
  }

  .header__content h2 {
    margin-inline-start: unset;
  }

  .header__content h1 {
    text-align: left;
  }

  .banner__card {
    padding: 1.5rem;
  }

  .banner__card:nth-child(1) {
    grid-area: 1/1/3/2;
  }

  .order__grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .event__content {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .event__image {
    grid-area: 1/2/2/3;
    width: 80% !important;
  }

  .event__details,
  .event__details .section__header {
    text-align: left;
  }

  .reservation__container form {
    max-width: 600px;
    grid-template-columns: repeat(2, 1fr);
  }

  .reservation img {
    display: flex;
    position: absolute;
    z-index: -1;
  }

  .reservation__bg-1 {
    left: 0;
    top: 0;
    width: clamp(100px, 25vw, 350px);
  }

  .reservation__bg-2 {
    right: 0;
    bottom: 0;
    width: clamp(100px, 20vw, 250px);
  }

  .footer__content {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer__links li {
    justify-content: flex-end;
  }

  .footer__socials {
    justify-content: flex-end;
  }
}

@media (width > 1024px) {
  .order__grid {
    gap: 2rem;
  }
}

.form-container {
  max-width: 600px;
  grid-template-columns: repeat(2, 1fr);
}
.textcenter{
  text-align: center;
}

.catering{
  display: flex;
  justify-content: center;
}


/* General styling for the select tag */
select {
  width: 100%;
  height: 44px;
  padding: 5px; /* Add some padding for better look */
  font-size: 16px; /* Increase font size for better readability */
  border-radius: 5px; /* Optional: round the corners */
  border: 1px solid #ccc; /* Add a border */
  background-color: #fff; /* Set the background color */
  color: #333; /* Set the text color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Styling for the options */
select option {
  padding: 10px; /* Increase padding for options */
  background-color: #fff; /* Set the background color */
  color: #333; /* Set the text color */
}

/* Optional: Add hover effect for select tag */
select:hover {
  border-color: #888; /* Change border color on hover */
}

/* Optional: Add focus effect for select tag */
select:focus {
  outline: none; /* Remove default outline */
  border-color: #007BFF; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a focus shadow */
}
.inputClass{
  padding: 0.75rem 1rem;
  width: 292px;
  height: 44px;
    outline: none;
    border: 1px solid var(--text-dark);
    font-size: 1rem;
    color: var(--text-dark);
}
.mobilelogo{
  width: 70px;
}

/* Basic styles */


.nav__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.nav__logo {
  display: flex;
  align-items: center;
}

/* Burger menu button */
.nav__menu__btn {
  font-size: 24px;
  cursor: pointer;
  display: none; /* Initially hidden */
}

/* Navigation links */
.nav__links {
  display: flex;
  list-style: none;
  gap: 2rem;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .nav__menu__btn {
    display: block; /* Show burger button on small screens */
  }

  .nav__links a {
    font-weight: 600;
    white-space: nowrap;
    color: #fff;
    transition: 0.3s;
}

  /* .nav__links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background: #fff;
    width: 100%;
    height: calc(100vh - 60px);
    padding-top: 1rem;
    text-align: center;
    transition: all 0.3s ease;
  } */

  .nav__links li {
    margin: 1rem 0;
  }

  /* When the menu is open */
  .nav__links--open {
    display: flex;
    flex-direction: column;
    height: 200px;
    position: absolute;
    top: 292px;
    background: #ffc135;
    height: max-content;
  }

  .header__content h1{
    text-align: center;
  }
}
